
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route) {
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName,
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


	//activities routes

	//admin routes
	{
		path: '/admin/:fieldName?/:fieldValue?',
		name: 'adminlist',
		component: () => import('./pages/admin/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/admin/view/:id',
		name: 'adminview',
		component: () => import('./pages/admin/view.vue'),
		props: true
	},

	{
		path: '/admin/add',
		name: 'adminadd',
		component: () => import('./pages/admin/add.vue'),
		props: true
	},

	{
		path: '/admin/edit/:id',
		name: 'adminedit',
		component: () => import('./pages/admin/edit.vue'),
		props: true
	},


	//comments routes
	{
		path: '/comments/:fieldName?/:fieldValue?',
		name: 'commentslist',
		component: () => import('./pages/comments/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/comments/view/:id',
		name: 'commentsview',
		component: () => import('./pages/comments/view.vue'),
		props: true
	},

	{
		path: '/comments/add',
		name: 'commentsadd',
		component: () => import('./pages/comments/add.vue'),
		props: true
	},

	{
		path: '/comments/edit/:id',
		name: 'commentsedit',
		component: () => import('./pages/comments/edit.vue'),
		props: true
	},


	//events routes
	{
		path: '/events/:fieldName?/:fieldValue?',
		name: 'eventslist',
		component: () => import('./pages/events/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/events/view/:id',
		name: 'eventsview',
		component: () => import('./pages/events/view.vue'),
		props: true
	},
	{
		path: '/events/add',
		name: 'eventsadd',
		component: () => import('./pages/events/add.vue'),
		props: true
	},
	{
		path: '/events/edit/:id',
		name: 'eventsedit',
		component: () => import('./pages/events/edit.vue'),
		props: true
	},
	//feedbacks routes
	{
		path: '/feedbacks/:fieldName?/:fieldValue?',
		name: 'feedbackslist',
		component: () => import('./pages/feedbacks/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/feedbacks/view/:id',
		name: 'feedbacksview',
		component: () => import('./pages/feedbacks/view.vue'),
		props: true
	},
	//likes routes
	{
		path: '/likes/:fieldName?/:fieldValue?',
		name: 'likeslist',
		component: () => import('./pages/likes/list.vue'),
		props: route => passRouteToProps(route)
	},
	//messages routes
	{
		path: '/messages/:fieldName?/:fieldValue?',
		name: 'messageslist',
		component: () => import('./pages/messages/list.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/messages/add',
		name: 'messagesadd',
		component: () => import('./pages/messages/add.vue'),
		props: true
	},
	{
		path: '/messages/edit/:id',
		name: 'messagesedit',
		component: () => import('./pages/messages/edit.vue'),
		props: true
	},
	//supporttickets routes
	{
		path: '/supporttickets/:fieldName?/:fieldValue?',
		name: 'supportticketslist',
		component: () => import('./pages/supporttickets/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/supporttickets/view/:id',
		name: 'supportticketsview',
		component: () => import('./pages/supporttickets/view.vue'),
		props: true
	},

	{
		path: '/supporttickets/add',
		name: 'supportticketsadd',
		component: () => import('./pages/supporttickets/add.vue'),
		props: true
	},

	{
		path: '/supporttickets/edit/:id',
		name: 'supportticketsedit',
		component: () => import('./pages/supporttickets/edit.vue'),
		props: true
	},
	//users routes
	{
		path: '/management/:fieldName?/:fieldValue?',
		name: 'managementlist',
		component: () => import('./pages/users/management.vue'),
		props: route => passRouteToProps(route)
	},
	{
		path: '/users/:fieldName?/:fieldValue?',
		name: 'userslist',
		component: () => import('./pages/users/list.vue'),
		props: route => passRouteToProps(route)
	},

	{
		path: '/users/view/:id',
		name: 'usersview',
		component: () => import('./pages/users/view.vue'),
		props: true
	},
	{
		path: '/management/view/:id',
		name: 'managementview',
		component: () => import('./pages/users/managementview.vue'),
		props: true
	},

	{
		path: '/index/register',
		name: 'usersuserregister',
		component: () => import('./pages/index/userregister.vue'),
		props: true
	},

	{
		path: '/account/edit',
		name: 'usersaccountedit',
		component: () => import('./pages/account/accountedit.vue'),
		props: true
	},

	{
		path: '/account',
		name: 'usersaccountview',
		component: () => import('./pages/account/accountview.vue'),
		props: true
	},

	{
		path: '/users/add',
		name: 'usersadd',
		component: () => import('./pages/users/add.vue'),
		props: true
	},
	{
		path: '/users/add_citizen',
		name: 'userscitizen',
		component: () => import('./pages/users/add_citizen.vue'),
		props: true
	},

	{
		path: '/users/edit/:id',
		name: 'usersedit',
		component: () => import('./pages/users/edit.vue'),
		props: true
	},
	//Password reset routes
	{
		path: '/index/forgotpassword',
		name: 'forgotpassword',
		component: () => import('./pages/index/forgotpassword.vue'),
		props: true
	},
	{
		path: '/index/resetpassword',
		name: 'resetpassword',
		component: () => import('./pages/index/resetpassword.vue'),
		props: true
	},
	{
		path: '/index/resetpassword_completed',
		name: 'resetpassword_completed',
		component: () => import('./pages/index/resetpassword_completed.vue'),
		props: true
	},

	{
		path: '/index/verifyotp',
		name: 'verifyotp',
		component: () => import('./pages/index/verifyotp.vue')
	},


	{
		path: '/error/forbidden',
		name: 'forbidden',
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{
		path: '/error/notfound',
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)',
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {

	const auth = useAuth();


	const user = auth.user;
	if (user) {
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else {
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion) {
			if (savedPostion) return savedPostion;
			return { top: 0 }
		}
	});

	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);

		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/', query: { nexturl: to.fullPath } });
		}

		if (user && to.name == "index") {
			//already logged in, show home when try to access index page
			return next({ path: "/home" });
		}

		//navigate to redirect url if available
		if (to.name == "home" && to.query.nexturl) {
			return next({ path: to.query.nexturl });
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
